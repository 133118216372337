<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-17 22:26:10
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-02 15:15:21
-->
<template>
  <div>
    <popup v-model="syncShow" class="flex-y center popup">
      <div class="popup__title">驳回说明</div>
      <div class="popup__text">
        <textarea
          name=""
          id=""
          :maxlength="maxLen"
          class="popup__area"
          placeholder="请输入驳回说明"
          v-model="text"
        ></textarea>
        <div class="popup__max">限{{ maxLen }}字内</div>
      </div>
      <div class="btns">
        <div class="btn btn--submit" @click="submit">提交</div>
        <div class="btn" @click="cancel">取消</div>
      </div>
    </popup>
  </div>
</template>

<script>
import { Popup } from "vant";
export default {
  components: {
    popup: Popup,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      maxLen: 300,

      text: "", // 驳回原因

      syncShow: this.value,
    };
  },

  methods: {
    // 清空内容
    clear() {
      this.text = "";
    },

    submit() {
      this.$emit("onsubmit", { detail: { text: this.text } });
    },

    cancel() {
      this.$emit("input", false);
      this.text = "";
      this.$emit("oncancel");
    },
  },

  watch: {
    syncShow(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.syncShow = newVal;
    },
  },
};
</script>

<style scoped>
.popup {
  width: 553px;
  height: 679px;
  background-color: #fff;
  border-radius: 16px;
}

.popup__title {
  font-size: 36px;
  color: #333;
  line-height: 113px;
  font-weight: bold;
}

.popup__text {
  position: relative;
}

.popup__area {
  width: 456px;
  height: 406px;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  resize: none;
  font-size: 24px;
  box-sizing: border-box;
  padding: 24px 17px;
}

.popup__max {
  position: absolute;
  right: 18px;
  bottom: 23px;
  font-size: 24px;
  color: #ccc;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 456px;
  margin-top: 54px;
}

.btn {
  width: 192px;
  height: 66px;
  border-radius: 33px;
  background-color: #ccc;
  text-align: center;
  line-height: 66px;
  font-size: 28px;
  color: white;
}

.btn--submit {
  background-color: var(--main-color);
}

@media screen and (min-width: 900px) {
  .popup {
    height: unset;
  }

  .popup__title {
    font-size: 2.5vw;
    line-height: 6vw;
  }

  .popup__area {
    height: 20vw;
    font-size: 2vw;
    padding: 1vw 2vw;
  }

  .popup__max {
    font-size: 2vw;
    bottom: 1vw;
    right: 2vw;
  }

  .btns{
    margin: 3vw 0;
  }

  .btn{
    height: 4vw;
    width: 10vw;
    line-height: 4vw;
    font-size: 2vw;
  }
}
</style>