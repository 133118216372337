/*
 * @Descripttion: 报价单
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-13 13:30:00
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-13 13:31:06
 */
import request from "../request"

// 报价单详情
export const detail = function (data) {
    return request.post('/quotation/getQuotationDetail', data)
}

// 确认报价单
export const confirm = function (data) {
    return request.post('/quotation/confirmQuotation', data)
}