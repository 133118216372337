<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 16:32:01
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-02 15:08:22
-->
<template>
  <div class="memo">
    <div class="title">
      <div class="num">{{ title }}</div>
      <slot name="header" />
    </div>
    <div>
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String, // 标题
  },
};
</script>

<style scoped>
.memo {
  border-radius: 16px;
  position: relative;
  background: radial-gradient(circle at 100% 110px, transparent 16px, #fff 0)
      top right,
    radial-gradient(circle at 0 110px, transparent 16px, #fff 0) top left;
  background-size: 51% 100%;
  background-repeat: no-repeat;
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.2));
}

.memo::before,
.memo::after {
  content: "";
  display: table-cell;
}

.title {
  width: 100%;
  height: 110px;
  line-height: 110px;
  position: relative;
  display: flex;
  align-items: center;
}

.title::before {
  content: "";
  display: block;
  width: 10px;
  height: 47px;
  background: linear-gradient(#3360c7, #68eef4);
  margin-right: 11px;
  margin-left: 25px;
}

.title::after {
  content: "";
  width: calc(100% - 32px);
  height: 1px;
  background-color: #f4f5f5;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.num {
  font-size: 32px;
  color: #222;
  font-weight: bold;
  flex: 1;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.num::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 900px) {
  .memo {
    background: radial-gradient(circle at 100% 5vw, transparent 1vw, #fff 0) top
        right,
      radial-gradient(circle at 0 5vw, transparent 1vw, #fff 0) top left;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.2));
  }

  .title {
    height: 5vw;
  }

  .title::before {
    height: 2.5vw;
    width: 0.7vw;
    margin-left: 2vw;
    margin-right: 0.5vw;
  }
  .num {
    font-size: 1.5vw;
  }
}
</style>